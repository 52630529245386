import React from 'react'
import HeaderMenu from './HeaderMenu'
import Segmen1 from './CardSegmen1'
import Segmen2 from './CardSegment2'
import Segmen3 from './CardSegment3'
import Segmen4 from './CardSegment4'
import Segmen5 from './CardSegment5'
function Chart() {
  return (
    <div>
      {/* <HeaderMenu/> */}
      <Segmen1/>
      <Segmen2/>
      <Segmen3/>
      <Segmen4/>
      <Segmen5/>
    </div>
  )
}

export default Chart
