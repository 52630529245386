import React from 'react';
import CardList from './CardList';
import { Link } from 'react-router-dom';
import Segmen2 from './CardSegment2';

function SpotifyEmbed() {
  return (
    <iframe
      style={{ borderRadius: '12px' }}
      src="https://open.spotify.com/embed/episode/4eyUQryUvHt4KmOKH9rPKS?utm_source=generator&t=0"
      width="100%"
      height="152"
      frameBorder="0"
      allowFullScreen
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
    />
  );
}

function SpotifyEmbed2(){
  return(
    <iframe 
      style={{borderRadius:'12px'}} 
      src="https://open.spotify.com/embed/episode/3oQgXnr9WYbOwSBFCpEBJc?utm_source=generator&t=0" 
      width="100%" 
      height="152" 
      frameBorder="0" 
      allowfullscreen 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
      />
  );
}

function SpotifyEmbed3(){
  return(
    <iframe 
      style={{borderRadius:'12px'}} 
      src="https://open.spotify.com/embed/episode/4uRbiFEUfZmRVu85efFoGM?utm_source=generator&t=0" 
      width="100%" 
      height="152" 
      frameBorder="0" 
      allowfullscreen 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
      />
  );
}

function SpotifyEmbed4(){
  return(
    <iframe 
      style={{borderRadius:'12px'}} 
      src="https://open.spotify.com/embed/episode/1m7BlY42ZEO0b8ZjCBIVsH?utm_source=generator&t=0" 
      width="100%" 
      height="152" 
      frameBorder="0" 
      allowfullscreen 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
      />
  );
}

function SpotifyEmbed5(){
  return(
    <iframe 
      style={{borderRadius:'12px'}} 
      src="https://open.spotify.com/embed/episode/2lrXapawa2biyvSNpRpv4m?utm_source=generator&t=0" 
      width="100%" 
      height="152" 
      frameBorder="0" 
      allowfullscreen 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
    />
  );
}

function SpotifyEmbed6(){
  return(
    <iframe 
      style={{borderRadius:'12px'}} 
      src="https://open.spotify.com/embed/episode/4yGu5giVuZtQYdvEzZaVz7?utm_source=generator&t=0" 
      width="100%" 
      height="152" 
      frameBorder="0" 
      allowfullscreen 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
    />
  );
}

function SpotifyEmbed7(){
  return (
    <iframe 
      style={{borderRadius:'12px'}} 
      src="https://open.spotify.com/embed/episode/3Nh4yXA3k2EPRvzYk04qkD?utm_source=generator&t=0" 
      width="100%" 
      height="152" 
      frameBorder="0" 
      allowfullscreen 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
      />
  );
}

function SpotifyEmbed8(){
  return(
  <iframe 
    style={{borderRadius:'12px'}} 
    src="https://open.spotify.com/embed/episode/3frYvs6uUvJfoPGwzA9XgO?utm_source=generator&theme=0&t=0" 
    width="100%" 
    height="152" 
    frameBorder="0" 
    allowfullscreen 
    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
    loading="lazy"
    />
  );
}

function SpotifyEmbed9() {
  return(
    <iframe 
      style={{borderRadius:'12px'}} 
      src="https://open.spotify.com/embed/episode/3MhFLbRziAbHYYcId4usSq?utm_source=generator&t=0" 
      width="100%" 
      height="152" 
      frameBorder="0" 
      allowfullscreen 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
      />
  );
}

function SpotifyEmbed10(){
  return(
    <iframe 
      style={{borderRadius:'12px'}} 
      src="https://open.spotify.com/embed/episode/5CVN52xpmpyjO4BrJwXRpV?utm_source=generator&t=0" 
      width="100%" 
      height="152" 
      frameBorder="0" 
      allowfullscreen 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
      />
  );
}

function SpotifyEmbed11(){
  return(
    <iframe 
      style={{borderRadius:'12px'}} 
      src="https://open.spotify.com/embed/episode/3ezuvA8jUFkEDj0W54OBGa?utm_source=generator&t=0" 
      width="100%" 
      height="152" 
      frameBorder="0" 
      allowfullscreen 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"/>
  );
}

function SpotifyEmbed12(){
  return(
    <iframe 
      style={{borderRadius:'12px'}} 
      src="https://open.spotify.com/embed/episode/640b3GPM7yMPo7ivo7zgZX?utm_source=generator&t=0" 
      width="100%" 
      height="152" 
      frameBorder="0" 
      allowfullscreen 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
      />
  );
}

function SpotifyEmbed13(){
  return(
    <iframe 
      style={{borderRadius:'12px'}} 
      src="https://open.spotify.com/embed/episode/4fTyyH1ophRgRvOwy8tKOH?utm_source=generator&t=0" 
      width="100%" 
      height="152" 
      frameBorder="0" 
      allowfullscreen 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
      />
  );
}

function SpotifyEmbed14(){
  return(
    <iframe 
      style={{borderRadius:'12px'}} 
      src="https://open.spotify.com/embed/episode/41rmQ0VL2W5hjoQnYkrMDb?utm_source=generator&t=0" 
      width="100%" 
      height="152" 
      frameBorder="0" 
      allowfullscreen 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
      />
  );
}

function SpotifyEmbed15(){
  return(
    <iframe 
      style={{borderRadius:'12px'}} 
      src="https://open.spotify.com/embed/episode/3TsgKTCypESOdyQpnVVds0?utm_source=generator&t=0" 
      width="100%" 
      height="152" 
      frameBorder="0" 
      allowfullscreen 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
      />
  );
}

function SpotifyEmbed16(){
  return(
    <iframe 
      style={{borderRadius:'12px'}} 
      src="https://open.spotify.com/embed/episode/4RWHkkUukDtiI47tbtaCnn?utm_source=generator&t=0" 
      width="100%" 
      height="152" 
      frameBorder="0" 
      allowfullscreen 
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
      loading="lazy"
      />
  );
}

function SpotifyEmbed17(){
    return(
        <iframe 
            style={{borderRadius:'12px'}} 
            src="https://open.spotify.com/embed/episode/2SaX9JUkUcLBodtDAMsadC?utm_source=generator&t=0" 
            width="100%" 
            height="152" 
            frameBorder="0" 
            allowfullscreen 
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
            loading="lazy"
            />
    );
}

function SpotifyEmbed18(){
    return(
        <iframe 
            style={{borderRadius:'12px'}} 
            src="https://open.spotify.com/embed/episode/0eCfPsssaPCX54Z67ujSnq?utm_source=generator&t=0" 
            width="100%" 
            height="152" 
            frameBorder="0" 
            allowfullscreen 
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
            loading="lazy"
            />
    );
}

function SpotifyEmbed19(){
    return(
        <iframe 
            style={{borderRadius:'12px'}} 
            src="https://open.spotify.com/embed/episode/6Ejm24VGuwcaJdxraEzey0?utm_source=generator&t=0" 
            width="100%" 
            height="152" 
            frameBorder="0" 
            allowfullscreen 
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
            loading="lazy"
            />
    );
}

function SpotifyEmbed20(){
    return(
        <iframe 
            style={{borderRadius:'12px'}} 
            src="https://open.spotify.com/embed/episode/0oKKdgMWmNPZnXKIXVacXM?utm_source=generator&t=0" 
            width="100%" 
            height="152" 
            frameBorder="0" 
            allowfullscreen 
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
            loading="lazy"
            />
    );
}

function SpotifyEmbed21(){
    return(
        <iframe 
            style={{borderRadius:'12px'}} 
            src="https://open.spotify.com/embed/episode/1PN8DMRIiK8h4cXFnk7Kot?utm_source=generator&t=0" 
            width="100%" 
            height="152" 
            frameBorder="0" 
            allowfullscreen 
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
            loading="lazy"
            />
    );
}

function SpotifyEmbed22(){
    return(
        <iframe 
            style={{borderRadius:'12px'}} 
            src="https://open.spotify.com/embed/episode/7CQyx941clVOtcNUAxqJGc?utm_source=generator&t=0" 
            width="100%" 
            height="152" 
            frameBorder="0" 
            allowfullscreen 
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
            loading="lazy"
            />
    );
}

function Segmen1() {
  return (
    <div>
      <h2> OLD SEGMEN 1</h2>
      <SpotifyEmbed />
      <SpotifyEmbed2/>
      <SpotifyEmbed3/>
      <SpotifyEmbed4/>
      <SpotifyEmbed5/>
      <SpotifyEmbed6/>
      <SpotifyEmbed7/>
      <SpotifyEmbed8/>
      <SpotifyEmbed9/>
      <SpotifyEmbed10/>
      <SpotifyEmbed11/>
      <SpotifyEmbed12/>
      <SpotifyEmbed13/>
      <SpotifyEmbed14/>
      <SpotifyEmbed15/>
      <SpotifyEmbed16/>
      <h2>LATEST SEGMEN 1</h2>
      <SpotifyEmbed17/>
      <SpotifyEmbed18/>
      <SpotifyEmbed19/>
      <SpotifyEmbed20/>
      <SpotifyEmbed21/>
      <SpotifyEmbed22/>
      {/* <Link to='/CardSegment2'>GO TO NEXT SEGMENT 2</Link>     */}
    </div>
  );
}

export default Segmen1;
