import React from "react";
import "./Footer.css";
import Segmen1 from "./CardSegmen1";
// import ImageGallery from "./ImageGalery";

export default function Footer() {
  return (
    <div className="bg-primary-2 footer-container">
      {/* <Segmen1/> */}
      {/* <ImageGallery/> */}
      <h1 className="footer-title">SPIN RADIO</h1>
    </div>
  );
}
